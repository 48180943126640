import React from "react";
import { graphql } from "gatsby";
import Header from "Commons/Header";
import Footer from "Commons/Footer";
import InternalPage from "Commons/InternalPage";
import { Helmet } from "react-helmet";
import useLang from "Commons/Hooks/useLang";

const PenetrationTesterPage = ({ data }) => {
  const [lang] = useLang();
  const {
    penetrationTesterPageTitle,
    penetrationTesterTitle,
    penetrationTesterDescription,
  } = data.allContentfulOpenings.nodes[lang];
  return (
    <>
      <Helmet>
        <title>{penetrationTesterPageTitle}</title>
      </Helmet>
      <Header noScrollEffect />
      <InternalPage
        title={penetrationTesterTitle}
        richText={penetrationTesterDescription}
      />
      <Footer />
    </>
  );
};

export const query = graphql`
  query getPenetrationTesterTexts {
    allContentfulOpenings {
      nodes {
        penetrationTesterPageTitle
        penetrationTesterTitle
        penetrationTesterDescription {
          raw
        }
      }
    }
  }
`;

export default PenetrationTesterPage;
